import BaseService from '@src/services/BaseService'

class ExtraPointService extends BaseService {
    constructor() {
        super('dashboards/widgets/extra-point')
    }

    resume(params) {
        return this.axios.post(`${this.getUrl()}/resume`, params)
    }

    perChain(params) {
        return this.axios.post(`${this.getUrl()}/per-chain`, params)
    }

    perType(params) {
        return this.axios.post(`${this.getUrl()}/per-type`, params)
    }
}

export default new ExtraPointService()

