<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nPeriod"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-range-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disabled="loading"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nBusinessUnit"
                                class="label-pdv"
                                label-for="filter-business-unit"
                            >
                                <multiSelectWithService
                                    :id="'filter-business-unit'"
                                    ref="businessUnitMultiselect"
                                    v-model="filters.business_units"
                                    :service="'business-units'"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="filterParameters"
                                    :disabled="loading"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" :disabled="loading" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" :disabled="loading" @onClick="filter" />
                    </div>
                </div>
            </div>

            <additional-point-resume ref="additionalPointResume" />

            <b-row>
                <b-col md="6">
                    <additional-point-type-chart ref="additionalPointTypeChart" />
                </b-col>
                <b-col md="6">
                    <additional-point-chain-chart ref="additionalPointChainChart" />
                </b-col>
            </b-row>

            <div class="card card-pdv">
                <PageHeader :title="i18nAdditionalPoint" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="file" slot-scope="props">
                            <a v-if="props.rowData.file"
                                :href="props.rowData.file"
                                target="_blank">
                                <i class="fe-camera ml-1"/>
                            </a>
                            <i v-else class="fe-camera-off ml-1" />
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <additional-point-filter-modal ref="additionalPointFilterModal" :handle-submit="filter" />
    </Layout>
</template>

<script>
import ExtraPointService from '@src/services/dashboards/widgets/ExtraPointService'

import Layout from '@layouts/main'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import FilterButton from '@/src/components/FilterButton'
import additionalPointFilterModal from '@/src/router/views/dashboards/additionalPoint/additionalPointFilterModal.vue'
import additionalPointResume from '@/src/router/views/dashboards/additionalPoint/additionalPointResume.vue'
import additionalPointTypeChart from '@/src/router/views/dashboards/additionalPoint/additionalPointTypeChart.vue'
import additionalPointChainChart from '@/src/router/views/dashboards/additionalPoint/additionalPointChainChart.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        DateRangePicker,
        multiSelectWithService,
        MoreFiltersLabelButton,
        FilterButton,
        additionalPointFilterModal,
        additionalPointResume,
        additionalPointTypeChart,
        additionalPointChainChart,
    },
    mixins: [
        filterVuetable,
        vuetableFormatter,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: false,
            additionalParameters: {},
            perPage: 10,
            sortOrder: [
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ],
            fields: [
                {
                    name: 'created_at',
                    title: this.getI18n(i18nCommon, 'date'),
                    sortField: 'created_at',
                },
                {
                    name: 'point_of_sale_name',
                    title: this.getI18n('POS.TITLES', 'pos'),
                    sortField: 'point_of_sale_name',
                },
                {
                    name: 'business_unit_name',
                    title: this.getI18n(i18nCommon, 'business_unit'),
                    sortField: 'business_unit_name',
                },
                // {
                //     name: 'customer_extra_point',
                //     title: this.getI18n(i18nKey, 'TITLES.customer_extra_point'),
                //     sortField: 'customer_extra_point',
                //     formatter: this.booleanIcons,
                // },
                {
                    name: 'type_extra_point',
                    title: this.getI18n(i18nCommon, 'type'),
                    sortField: 'type_extra_point',
                },
                {
                    name: 'file',
                    title: this.getI18n(i18nCommon, 'photo'),
                },
            ],
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            filters: {
                date: {
                    start: moment().startOf('day').add(-30, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                business_units: [],
                regionals: [],
                channels: [],
                chains: [],
                flags: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.i18nAdditionalPoint,
                    active: true,
                },
            ]
        },
        i18nAdditionalPoint() {
            return this.getI18n(i18nKey, 'TITLES.additional_point_dashboard')
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
    },
    mounted() {
        this.filter()
    },
    methods: {
        createAdditionalParameters() {
            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                business_unit_id: this.filters.business_units.map((businessUnit) => businessUnit.id),
                regional_id: this.filters.regionals.map((regional) => regional.id),
                channel_id: this.filters.channels.map((channel) => channel.id),
                chain_id: this.filters.chains.map((chain) => chain.id),
                flag_id: this.filters.flags.map((flag) => flag.id),
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            if (appliedFilters) {
                this.filters = appliedFilters
            }
            this.additionalParameters = this.createAdditionalParameters()
            this.refresh()
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.additionalPointFilterModal.showModal(this.filters))
        },
        fetch(url, params) {
            return ExtraPointService.fetchVuetable('list', params)
        },
        refresh() {
            this.loading = true

            this.$refs.additionalPointResume.refresh(this.additionalParameters)
            this.$refs.additionalPointTypeChart.refresh(this.additionalParameters)
            this.$refs.additionalPointChainChart.refresh(this.additionalParameters)
            this.refreshTable()

            this.loading = false
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
    },
}
</script>
