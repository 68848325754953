import { render, staticRenderFns } from "./additionalPointIndex.vue?vue&type=template&id=84d7fffa&"
import script from "./additionalPointIndex.vue?vue&type=script&lang=js&"
export * from "./additionalPointIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports