<template>
    <b-row>
        <b-col md="4">
            <indicator-card
                ref="chartPosAudited"
                :title="i18nPosAudited"
                :busy="loading"
                :value="posAudited"
            />
        </b-col>
        <b-col md="4">
            <indicator-card
                ref="chartPosWithExtraPoint"
                :title="i18nPosWithExtraPoint"
                :busy="loading"
                :value="posWithExtraPoint"
            />
        </b-col>
        <b-col md="4">
            <indicator-card
                ref="chartPercentagePosWithExtraPoint"
                :title="i18PercentagePosWithExtraPoint"
                :busy="loading"
                :value="percentagePosWithExtraPoint"
                type="percentage"
                text-color="#64BB77"
            />
        </b-col>
    </b-row>
</template>

<script>
import ExtraPointService from '@src/services/dashboards/widgets/ExtraPointService'

import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import IndicatorCard from '@/src/components/dashboards/IndicatorCard'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        IndicatorCard,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    data() {
        return {
            i18nKey,
            loading: false,
            posAudited: 0,
            posWithExtraPoint: 0,
            percentagePosWithExtraPoint: 0,
        }
    },
    validations: {},
    computed: {
        i18nPosAudited() {
            return this.getI18n(i18nKey, 'TITLES.pos_audited')
        },
        i18nPosWithExtraPoint() {
            return this.getI18n(i18nKey, 'TITLES.pos_with_extra_point')
        },
        i18PercentagePosWithExtraPoint() {
            return this.getI18n(i18nKey, 'TITLES.percentage_pos_with_extra_point')
        },
    },
    methods: {
        refresh(parameters) {
            this.loading = true
            this.posAudited = 0
            this.posWithExtraPoint = 0
            this.percentagePosWithExtraPoint = 0

            ExtraPointService.resume(parameters)
                .then((response) => {
                    this.posAudited = response.data.data.pos_audited
                    this.posWithExtraPoint = response.data.data.pos_with_pe
                    this.percentagePosWithExtraPoint = this.posAudited > 0 ? Math.round(this.posWithExtraPoint * 100 / this.posAudited) : 0
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
    },
}
</script>
